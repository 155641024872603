/* eslint-disable */
import Vue from 'vue'

(function () {
  const Preview = () => import(/* webpackChunkName: 'form.demo' */ './Preview.vue')

  const FORMS = {
    demo: () => import(
      /* webpackChunkName: 'form.demo' */
      './forms/Demo.vue'),
    'vnvc-online-booking-v3': () => import(
      /* webpackChunkName: 'form.vnvc-online-booking-v3' */
      './forms/VnvcOnlineBooking.vue'),
    'online-booking-survey': () => import(
      /* webpackChunkName: 'form.online-booking-survey' */
      './forms/OnlineBookingSurvey.vue'),
    'book-store': () => import(
      /* webpackChunkName: 'form.book-store' */
      './forms/BookStore.vue'),
    'signup-consultation-6m-v1': () => import(
      /* webpackChunkName: 'form.signup-consultation-6m-v1' */
      './forms/SignupConsultation6m.vue'),
    'ta-vaccine-booking-v4': () => import(
      /* webpackChunkName: 'form.ta-vaccine-booking-v4' */
      './forms/TAVaccineBooking.vue'),
    'vaccine-covid19-register-v5': () => import(
      /* webpackChunkName: 'form.vaccine-covid19-register-v5' */
      './forms/VaccineCovid19Register.vue'),
    'vaccine-covid19-register-ads-v5': () => import(
      /* webpackChunkName: 'form.vaccine-covid19-register-ads-v5' */
      './forms/VaccineCovid19RegisterAds.vue')      
  }

  const ref = document.getElementById('eplus-form-embed')
  if (!ref) {
    new Vue({
      render: h => h(Preview)
    }).$mount('#app')
    return
  }

  const key = ref.getAttribute('data-form')
  const source = ref.getAttribute('data-source')
  const next = ref.getAttribute('data-next')
  const home = ref.getAttribute('data-home')
  const id = `eplus-form-embed__${key}`
  const div = document.createElement('div')
  div.setAttribute('id', id)
  ref.parentNode.insertBefore(div, ref)
  const props = {}
  if (source) props.source = source
  if (home) props.home = home
  if (next) props.next = next
  new Vue({
    render: h => h(FORMS[key], { props }),
  }).$mount(div)
})()
